import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'napa-edit-icon',
  standalone: true,
  templateUrl: './edit-icon.component.html',
  styleUrl: './edit-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditIconComponent {}
