<svg
  width="16px"
  height="16px"
  viewBox="0 0 16 16"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g id="A-1.0-Sessions" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Sessions" transform="translate(-1064, -304)">
      <g id="Group-5" transform="translate(1052, 212)">
        <g id="Group" transform="translate(0, 80)">
          <g id="icon/move" transform="translate(12, 12)">
            <rect id="Rectangle" x="0" y="0" width="16" height="16"></rect>
            <path
              d="M7,1 L9,1 C10.1045695,1 11,1.8954305 11,3 L11,4 L11,4 L5,4 L5,3 C5,1.8954305 5.8954305,1 7,1 Z"
              id="Rectangle"
              stroke="currentColor"
              stroke-width="2"
            ></path>
            <path
              d="M13,7 L12.2189913,13.2480695 C12.0938841,14.2489271 11.2430821,15 10.2344356,15 L5.76556444,15 C4.7569179,15 3.90611589,14.2489271 3.78100868,13.2480695 L3,7 L3,7"
              id="Path"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
            ></path>
            <line
              x1="1"
              y1="4"
              x2="15"
              y2="4"
              id="Path-4"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
            ></line>
            <line
              x1="4"
              y1="12"
              x2="12"
              y2="12"
              id="Path-4"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
            ></line>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
