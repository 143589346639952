<svg
  width="16px"
  height="16px"
  viewBox="0 0 16 16"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g id="A-1.0-Sessions" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Sessions" transform="translate(-1064, -264)">
      <g id="Group-5" transform="translate(1052, 212)">
        <g id="Group" transform="translate(0, 40)">
          <g id="icon/move" transform="translate(12, 12)">
            <rect id="Rectangle" x="0" y="0" width="16" height="16"></rect>
            <path
              d="M13.0104076,2.0753788 L14.4246212,3.48959236 C14.8151455,3.88011665 14.8151455,4.51328163 14.4246212,4.90380592 L5.93933983,13.3890873 L2.96576715,14.8758736 C2.47178865,15.1228629 1.87111561,14.9226385 1.62412637,14.42866 C1.48336262,14.1471325 1.48336262,13.8157603 1.62412637,13.5342328 L3.1109127,10.5606602 L11.5961941,2.0753788 C11.956678,1.71489484 12.5239091,1.6871653 12.9162003,1.99219019 L13.0104076,2.0753788 Z"
              id="Rectangle"
              stroke="currentColor"
              stroke-width="2"
            ></path>
            <line
              x1="8.8890873"
              y1="5.6109127"
              x2="12.8890873"
              y2="5.6109127"
              id="Path-4"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              transform="translate(10.8891, 5.6109) rotate(-315) translate(-10.8891, -5.6109)"
            ></line>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
