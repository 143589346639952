import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'napa-delete-icon',
  standalone: true,
  templateUrl: './delete-icon.component.html',
  styleUrl: './delete-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteIconComponent {}
